import styled, { css } from 'styled-components';
import { widthBp, sizes, serif } from '../lib/styles';

export const H2 = styled.h2`
  font-weight: 400;
  font-style: normal;
  display: inline;
  font-size: 42px;
  color: black;
  letter-spacing: -0.008em;
  @media (prefers-color-scheme: light) {
    color: black;
  }
  @media (prefers-color-scheme: dark) {
    color: white;
  }
  @media (${widthBp(sizes.laptop)}) {
    font-size: 50px;
  }
`;

export const H3 = styled.h3`
  font-size: 32px;
  font-weight: 400;
  font-style: normal;
  margin-top: 0;
  @media (prefers-color-scheme: light) {
    color: black;
  }
  @media (prefers-color-scheme: dark) {
    color: white;
  }
  @media (${widthBp(sizes.laptop)}) {
    font-size: 38px;
  }
`;

export const P = styled.p`
  ${serif}
  font-size: 17px;
  white-space: pre-wrap;
  ${props => css`
    ${props.noTop && 'margin-top: 3px'};
  `}
  @media (${widthBp(sizes.laptop)}) {
    font-size: 18px;
  }
`;
