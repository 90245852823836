import React from 'react';
import PropTypes from 'prop-types';
import { Head } from 'react-static';
import { useTranslation } from 'react-i18next';

import Images from './Images';
import Description from './Description';
import NextProject from './NextProject';
import { PaddingWrap } from './layout';

const Portfolio = ({ portfolio }) => {
  const {
    id,
    images,
    aspectRatio,
    nextPath,
  } = portfolio;
  const { t } = useTranslation();
  const isError404 = id === 'error404';
  return (
    <PaddingWrap>
      <Head title={`Fabian Schroder - ${t(`projects.${id}.title`)}`} />
      <Description id={id} />
      <Images
        images={images}
        aspectRatio={aspectRatio}
      />
      <NextProject
        to={nextPath}
        label={isError404 ? t('generic.back') : t('projects.next')}
      />
    </PaddingWrap>
  );
};

Portfolio.propTypes = {
  portfolio: PropTypes.shape({
    id: PropTypes.string.isRequired,
    images: PropTypes.array.isRequired,
    aspectRatio: PropTypes.string.isRequired,
    nextPath: PropTypes.string.isRequired,
  }).isRequired,
};

export default Portfolio;
