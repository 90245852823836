import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from '@reach/router';
import { widthBp, sizes } from '../lib/styles';
import { ModalConsumer } from './ModalProvider';

const Next = ({ to, label }) => (
  <ModalConsumer>
    {({ isMenuOpen }) => (
      <NextWrap>
        <NextLink
          to={to}
          tabIndex={isMenuOpen ? '-1' : '0'}
        >
          {label}
        </NextLink>
      </NextWrap>
    )
  }
  </ModalConsumer>
);

const NextWrap = styled.footer`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  width: 100%;
  padding: 40px 0 44px 95px;
  @media (${widthBp(sizes.laptop)}) {
    padding: 60px 0 90px 60px;
    justify-content: center;
  }
`;

const NextLink = styled(Link)`
  position: relative;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-size: 32px;
  color: black;
  @media (prefers-color-scheme: light) {
    color: black;
  }
  @media (prefers-color-scheme: dark) {
    color: white;
  }
  @media (${widthBp(sizes.laptop)}) {
    font-size: 38px;
    &:hover, &:focus {
      background-color: black;
      color: white;
    }
    @media (prefers-color-scheme: light) {
      &:hover, &:focus {
        background-color: black;
        color: white;
      }
    }
    @media (prefers-color-scheme: dark) {
      &:hover, &:focus {
        background-color: white;
        color: black;
      }
    }
  }
`;

Next.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Next;
