import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { widthBp, sizes } from '../lib/styles';

const Image = styled(LazyLoadImage)`
  height: auto;
  width: 100%;
  position: absolute;
  user-select: none;
  @media (${widthBp(sizes.handheld)}) {    
    ${({ vertical }) => vertical === 'true' && 'width: auto; height: 100%'};
  }
`;

const Figure = styled.figure`
  margin-bottom: ${props => (props.isGalleryImage ? '20px' : '50px')};
  @media (${widthBp(sizes.handheld)}) { 
    margin-bottom: ${props => (props.isGalleryImage ? '20px' : '150px')};
  }
  @media (${widthBp(sizes.laptop)}) { 
    margin-bottom: ${props => (props.isGalleryImage ? '20px' : '250px')};
  }
  .lazy-load-image-background {
    display: block !important;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: ${props => (props.aspectRatio ? `${props.aspectRatio}%` : '66.67%')};
    margin-right: auto;
    margin-left: auto;
    @media (${widthBp(sizes.handheld)}) { 
      ${({ isVertical, aspectRatio }) => isVertical && `
        width: 53%;
        ${aspectRatio && `padding-bottom: ${aspectRatio * 0.53}%`}
      `};
    }
    .lazy-load-image-background.opacity {
      background-image: none !important;
      opacity: 0;
    }

    .lazy-load-image-background.opacity.lazy-load-image-loaded {
      opacity: 1;
      transition: opacity .3s;
    }
  }
  :last-of-type {
    margin-bottom: 0;
  }
`;

const Caption = styled.figcaption`
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  text-align: center;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 10px;
  margin-top: 6px;
  @media (${widthBp(sizes.laptop)}) {font-size: 18px;}
`;

const Images = ({
  images,
  aspectRatio,
}) => (
  images.map((image) => {
    const {
      caption,
      url,
      urlMedium,
      isVertical,
      isGalleryImage,
    } = image;
    const isVerticalImage = !!isVertical;
    return (
      <Figure
        key={url}
        aspectRatio={image.aspectRatio || aspectRatio}
        isGalleryImage={!!isGalleryImage}
        isVertical={isVerticalImage}
      >
        <Image
          effect="opacity"
          srcSet={`
            ${urlMedium} 1000w,
            ${url} 2000w
          `}
          vertical={`${isVerticalImage}`}
          sizes="(max-width: 736px) 960px, 2000px"
          src={image.url}
          threshold={1000}
        />
        {caption && (
          <Caption>
            {caption}
          </Caption>
        )}
      </Figure>
    );
  }));

Images.defaultProps = {
  isVertical: false,
  isGalleryImage: false,
  link: null,
};

Images.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string.isRequired,
    urlMedium: PropTypes.string.isRequired,
    caption: PropTypes.string,
    isVertical: PropTypes.bool,
    isGalleryImage: PropTypes.bool,
    link: PropTypes.string,
  })).isRequired,
  aspectRatio: PropTypes.string.isRequired,
};

export default Images;
