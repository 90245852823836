import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { H2, P } from './typo';
import { widthBp, sizes } from '../lib/styles';

const Description = ({ id }) => {
  const { t } = useTranslation();
  return (
    <DescriptionWrap>
      <H2>{t(`projects.${id}.title`)}</H2>
      <ContentSub>{t(`projects.${id}.year`)}</ContentSub>
      {<Column dangerouslySetInnerHTML={{ __html: t(`projects.${id}.description`) }} />}
    </DescriptionWrap>
  );
};

const DescriptionWrap = styled.section`
  position: relative;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 40px;
  @media (${widthBp(sizes.laptop)}) {
    margin-bottom: 60px;
    padding-left: 60px;
  }
  @media (${widthBp(sizes.desktop)}) {
    max-width: 84%;
  }
`;

const Column = styled(P)`
  column-count: 1;
  @media (${widthBp(sizes.handheld)}) {
    column-count: 2;
  }
  cite {
    display: inline-block;
    font-size: 15px;
    margin-top: 3px;
  }
`;

const ContentSub = styled.span`
  display: block;
  margin-bottom: 15px;
  @media (${widthBp(sizes.handheld)}) {
    display: inline;
    padding-left: 18px;
    margin: 0;
  }
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
`;

Description.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Description;
