/* eslint-disable global-require, max-len */
export default {
  id: 'error404',
  path: '*',
  nextPath: '/',
  nextPathLabel: 'Back',
  aspectRatio: '66.67',
  images: [
    {
      url: require('../assets/images/404/404.jpg'),
      urlMedium: require('../assets/images/404/404.jpg'),
      // caption: 'Pipes, 2014',
    },
  ],
};
